import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactCountryFlag from 'react-country-flag';
import { Redirect } from 'react-router-dom';
import Switch from 'react-switch';
import { Helmet } from 'react-helmet';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import {
  getLocationList,
  getLocationData,
  getOpsgenieRegionName,
  statusCodeToReasonPhrase,
} from '../../../utils/helper';
import { getItemStorage, removeItemStorage } from '../../../utils/storage';
import Navbar from '../../layout/Navbar/Navbar';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import Modal from './../../layout/Modal/Modal';
import GA from './../../../components/Tracking/GoogleAnalytics';

import AppContext from '../../../context/app-context';
//css
import './MonitorForm.css';

// Import icons
import icon_no_alert_channel from './../../../assets/icons/mailbox.svg';

export default class EditMonitorForm extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor (props) {
    super(props);
    this.state = {
      monitorId: props.match.params.monitorId, // capture from url (/monitors/:monitorId)
      form_name: '',
      form_name_placeholder: 'Google Api',
      form_url: '',
      form_url_placeholder: 'https://www.google.com/api/',
      form_url_validate: false,
      form_requestMethod: 'GET',
      form_expected_status_code: 200,
      form_expected_body: '',
      locationList: [],
      selectedLocations: [],
      followRedirect: true,
      httpHeader_field_key_placeholder: 'Key',
      httpHeader_field_value_placeholder: 'Value',
      selectedHttpTab: 'httpHeader',
      httpHeader_elements: [
        {
          key: '', // Content-Type
          value: '', // application/x-www-form-urlencoded (Form params) - application/json (JSON) -
        },
      ],
      httpBodyFormat: 'none',
      httpBodyContent: '',
      form_threshold: 500,
      form_threshold_error: '',
      form_maxResponseTime: 10,
      form_maxResponseTime_error: '',
      form_expectedBodyCharacterCount_error: '',
      isLoading: false,
      form_response: '',
      form_response_status: 'warning',
      formIsSubmited: false,
      // url_regex_validator: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      url_regex_validator:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/gm, //  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'loading',
          link: '#',
          isLink: false,
        },
        {
          name: 'Edit',
          link: '#',
          isLink: false,
        },
      ],

      selectedSettingtab: 'request',
      loadingMonitorData: false,
      isloadingAlertChannels: false,
      channelList: [],
      form_frequency: 1,
      showModal_deleteMonitor: false,
      modalResponse_status: 'warning',
      modalResponse: '',
      monitorNotFound: false,
      hasActiveAccount: true,
      isActiveMonitor: false,
      tabs_form_selectedTab: 'request',
    };

    this.handleChange_formMonitorName =
      this.handleChange_formMonitorName.bind(this);
    this.handleChange_formRequestMethod =
      this.handleChange_formRequestMethod.bind(this);
    this.handleChange_formExpectedStatusCode =
      this.handleChange_formExpectedStatusCode.bind(this);
    this.handleChange_formExpectedBody =
      this.handleChange_formExpectedBody.bind(this);
    this.handleChange_formURL = this.handleChange_formURL.bind(this);
    this.handleChange_formSelectLocation =
      this.handleChange_formSelectLocation.bind(this);
    this.handleChange_formFollowRedirect =
      this.handleChange_formFollowRedirect.bind(this);
    this.handleChange_selectedHttpTab =
      this.handleChange_selectedHttpTab.bind(this);
    this.handleChange_formHttpHeader_removeLine =
      this.handleChange_formHttpHeader_removeLine.bind(this);
    this.handleChange_formHttpHeader_addLine =
      this.handleChange_formHttpHeader_addLine.bind(this);
    this.handleChange_formHttpHeader_lineContent =
      this.handleChange_formHttpHeader_lineContent.bind(this);
    this.handleChange_formHttpBody__chooseFormat =
      this.handleChange_formHttpBody__chooseFormat.bind(this);
    this.hangleChange_formHttpBody_content =
      this.hangleChange_formHttpBody_content.bind(this);
    this.handleChange_formMonitorThreshold =
      this.handleChange_formMonitorThreshold.bind(this);
    this.handleChange_formMonitorMaxResponseTime =
      this.handleChange_formMonitorMaxResponseTime.bind(this);
    this.handleChange_formAlertSettings =
      this.handleChange_formAlertSettings.bind(this);
    this.handleChange_formFrequency =
      this.handleChange_formFrequency.bind(this);
    this.openModal_deleteMonitor = this.openModal_deleteMonitor.bind(this);
    this.closeModal_deleteMonitor = this.closeModal_deleteMonitor.bind(this);
    this.deleteMonitor = this.deleteMonitor.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleChange_formSwitchActivatedMonitor =
      this.handleChange_formSwitchActivatedMonitor.bind(this);
    this.handleChange__form_selectTab =
      this.handleChange__form_selectTab.bind(this);
  }

  componentDidMount () {
    this.setState({
      locationList: getLocationList('active'),
    });

    // Get the alert channel Subscriptions
    this.getAlertChannels();

    // Get current monitor Data
    this.getMonitorData();

    // Get current account Data
    // this.getAccountData();
  }

  handleChange_formMonitorName (event) {
    this.setState({
      form_name: event.target.value,
    });
  }

  handleChange_formRequestMethod (event) {
    //console.log(event.target.value);
    this.setState({
      form_requestMethod: event.target.value,
    });
  }

  handleChange_formExpectedStatusCode (event) {
    this.setState({
      form_expected_status_code: event.target.value,
    });
  }

  handleChange_formExpectedBody (event) {
    if (event.target.value.length > 200) {
      this.setState({
        form_expectedBodyCharacterCount_error: 'Expected body must be less than 200 characters',
      });
    } else {
      this.setState({
        form_expectedBodyCharacterCount_error: '',
      });
    }
    this.setState({
      form_expected_body: event.target.value,
    });
  }

  handleChange_formURL (event) {
    var isValid = false;
    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== '' &&
      event.target.value.match(this.state.url_regex_validator) !== null
    )
      isValid = true;

    this.setState({
      form_url: event.target.value,
      form_url_validate: isValid,
    });
  }

  handleChange_formSelectLocation (event) {
    // event.target.value;
    const selectedLocations = [...this.state.selectedLocations];
    if (event.target.checked) {
      this.setState({
        selectedLocations: [
          ...this.state.selectedLocations,
          event.target.value,
        ],
      });
    } else {
      if (selectedLocations.length > 1) {
        const found = selectedLocations.find(
          (element) => element === event.target.value
        );
        const index = selectedLocations.indexOf(found);
        selectedLocations.splice(index, 1);
        this.setState({
          selectedLocations,
        });
      }
    }

    //console.log(this.state.selectedLocations);
  }

  handleChange_formFollowRedirect (event) {
    this.setState({
      followRedirect: event.target.value,
    });
  }

  handleChange_selectedHttpTab (event) {
    this.setState({
      selectedHttpTab: event.target.getAttribute('data-target'),
    });
  }

  handleChange_formHttpHeader_lineContent (e, index) {
    const { name, value } = e.target;
    const httpHeader_elements = [...this.state.httpHeader_elements];

    httpHeader_elements[index][name] = value;
    this.setState({
      httpHeader_elements,
    });
  }

  handleChange_formHttpHeader_removeLine (e, index) {
    let httpHeader_elements = [...this.state.httpHeader_elements];

    if (httpHeader_elements.length >= 2) {
      httpHeader_elements.splice(index, 1);
      this.setState({ httpHeader_elements });
    }
  }

  handleChange_formHttpHeader_addLine (event) {
    // this.setState( prevState => ({
    //     httpHeader_elements: [...prevState.httpHeader_elements , { key: "", value: "" }]
    // }));
    this.setState({
      httpHeader_elements: [
        ...this.state.httpHeader_elements,
        { key: '', value: '' },
      ],
    });
  }

  handleChange_formHttpBody__chooseFormat (event) {
    let additionalHeaderLine = {};
    if (event.target.value === 'json')
      additionalHeaderLine = {
        key: 'Content-type',
        value: 'application/json',
      };
    else if (event.target.value === 'form-params')
      additionalHeaderLine = {
        key: 'Content-type',
        value: 'application/x-www-form-urlencoded',
      };
    else if (event.target.value === 'raw-data')
      additionalHeaderLine = {
        key: 'Content-type',
        value: 'text/plain',
      };
    const contentTypeIndex = this.state.httpHeader_elements.findIndex(
      (x) => x.key === 'Content-type'
    );
    const emptyIndex = this.state.httpHeader_elements.findIndex(
      (x) => x.key === '' && x.value === ''
    );

    if (typeof contentTypeIndex !== 'undefined' && contentTypeIndex >= 0) {
      // Content type element is exist : Update
      let httpHeader_elements = [...this.state.httpHeader_elements];
      if (event.target.value === 'none') {
        // delete Content-type header line
        httpHeader_elements.splice(contentTypeIndex, 1);
        if (httpHeader_elements.length <= 0) {
          this.setState({
            httpBodyFormat: event.target.value,
            httpHeader_elements: [
              ...httpHeader_elements,
              { key: '', value: '' },
            ],
          });
        } else {
          this.setState({
            httpBodyFormat: event.target.value,
            httpHeader_elements: httpHeader_elements,
          });
        }
      } else {
        httpHeader_elements[contentTypeIndex] = additionalHeaderLine;
        this.setState({
          httpBodyFormat: event.target.value,
          httpHeader_elements: httpHeader_elements,
        });
      }
    } else {
      // Not exist : Insert
      if (typeof emptyIndex !== 'undefined' && emptyIndex >= 0) {
        let httpHeader_elements = [...this.state.httpHeader_elements];
        httpHeader_elements[emptyIndex] = additionalHeaderLine;
        this.setState({
          httpBodyFormat: event.target.value,
          httpHeader_elements: httpHeader_elements,
        });
      } else {
        this.setState({
          httpBodyFormat: event.target.value,
          httpHeader_elements: [
            ...this.state.httpHeader_elements,
            additionalHeaderLine,
          ],
        });
      }
    }
  }

  hangleChange_formHttpBody_content (event) {
    this.setState({
      httpBodyContent: event.target.value,
    });
  }

  handleChange_formMonitorThreshold (event) {
    const value = event.target.value;
    let error = '';
    if (typeof value === 'undefined' || value === null)
      error = 'Threshold is required!';
    else if (value <= 0 || value > 30000)
      error = 'Threshold must be a number between 1 and 30000 ms';

    this.setState({
      form_threshold: value,
      form_threshold_error: error,
    });
  }

  handleChange_formMonitorMaxResponseTime (event) {
    const value = event.target.value;
    let error = '';
    if (typeof value === 'undefined' || value === null)
      error = 'Limit Timeout is required!';
    else if (value <= 0 || value > 30)
      error = 'Limit Timeout must be a number between 1 and 30 seconds';

    this.setState({
      form_maxResponseTime: value,
      form_maxResponseTime_error: error,
    });
  }

  handleChange_formAlertSettings (checked, event, id) {
    let channelList = this.state.channelList;

    const found = channelList.findIndex((x) => x.id === id);

    // mark it as activated
    if (found >= 0 && typeof channelList[found] !== 'undefined') {
      channelList[found].activated = checked;
    }

    this.setState(
      {
        channelList: channelList,
      },
      () => {
        //console.log(this.state.channelList)
      }
    );
  }

  handleChange_formFrequency (event) {
    this.setState({
      form_frequency: event.target.value,
    });
  }

  handleChange_formSwitchActivatedMonitor = async (checked, event, id) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      //console.log(this.state);

      // refont custom Headers List => to be easy as learning
      // let customHeaders = {};
      // this.state.httpHeader_elements.map(item => {
      // 	customHeaders[item.key] = item.value;
      // });

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            is_active: checked,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          // stop loading effect
          this.setState({
            isLoading: false,
            form_response: 'Your monitor has been successfully updated',
            form_response_status: 'success',
            isActiveMonitor: checked,
          });
          //console.log(res);
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              form_response: 'You are not authorized to do this operation',
              form_response_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });

      return;
    }
  };

  openModal_deleteMonitor (event) {
    this.setState({
      showModal_deleteMonitor: true,
    });
  }

  closeModal_deleteMonitor (event) {
    this.setState({
      showModal_deleteMonitor: !this.state.showModal_deleteMonitor,
      modalResponse: '',
    });
  }

  handleChange__form_selectTab (event) {
    const tabName = event.currentTarget.dataset.name;
    this.setState({
      tabs_form_selectedTab: tabName,
    });
  }

  /**
   *
   *
   * @memberof EditMonitorForm	Get current monitor Data
   */
  getMonitorData = async () => {
    this.setState({
      loadingMonitorData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          const data = res.data;

          let headerElements = this.state.httpHeader_elements;
          const customHeaders = JSON.parse(
            decodeURIComponent(data.custom_headers)
          );
          if (typeof customHeaders !== 'undefined' && customHeaders !== null) {
            headerElements = Object.keys(customHeaders).map((item) => {
              return {
                key: item,
                value: customHeaders[item],
              };
            });
          }

          const breadcrumbs_items = this.state.breadcrumbs_items;
          breadcrumbs_items[1].name = data.name;
          breadcrumbs_items[1].link = '/monitors/' + data.id;
          breadcrumbs_items[1].isLink = true;
          this.setState({
            loadingMonitorData: false,
            form_name: data.name,
            form_url: data.url,
            form_requestMethod: data.method,
            form_url_validate: true,
            selectedLocations: data.locations,
            followRedirect: data.followredirects,
            httpHeader_elements: headerElements,
            httpBodyContent: decodeURIComponent(data.custom_body),
            httpBodyFormat: data.custom_body_format,
            form_expected_status_code: data.expected_status_code || 200,
            form_expected_body: data.expected_body || '',
            form_threshold: data.threshold,
            form_maxResponseTime: parseFloat(data.timeout) / 1000,
            form_frequency: data.frequency,
            breadcrumbs_items: breadcrumbs_items,
            isActiveMonitor: data.is_active,
          });
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              loadingMonitorData: false,
              monitorNotFound: true,
              breadcrumbs_items: (this.state.breadcrumbs_items[1] = {
                name: 'Monitor Not found',
                link: '#',
                isLink: false,
              }),
            });
          }
        });
    } else {
      this.setState({
        loadingMonitorData: false,
        monitorNotFound: true,
        breadcrumbs_items: (this.state.breadcrumbs_items[1] = {
          name: 'Monitor Not found',
          link: '#',
          isLink: false,
        }),
      });
    }
  };

  /**
   *
   *
   * @memberof EditMonitorForm
   */
  getAccountData = async () => {
    this.setState({
      isloadingAlertChannels: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          //console.log(res);
          res = res.data;
          if (typeof res !== 'undefined' && res !== null) {
            const data = res;

            this.setState({
              isloadingAlertChannels: false,
              channelList: data.alertChannelSubscriptions,
            });
            //console.log(data.alertChannelSubscriptions);

            // //console.log(data.alertChannelSubscriptions[0].subscriptions.find(e => e.activated === true));
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isloadingAlertChannels: false,
              hasActiveAccount: false,
            });
            // remote current account id from storage ( connectedUserData element )
            this.context.cleanUserAccount(accountId);
          }
        });
    } else {
    }
  };

  getAlertChannels = async () => {
    this.setState({
      isloadingAlertChannels: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}/alert-channels`,
          {
            params: {},
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          //console.log(res);
          const data = res.data;
          if (
            typeof res !== 'undefined' &&
            res !== null &&
            res.status === 200
          ) {
            this.setState({
              isloadingAlertChannels: false,
              channelList: data.data,
              // pageCount: Math.ceil(res.data.total / this.state.perPage),
              // responseList_message : null,
              // responseList_status :"success"
            });
            // //console.log(data.alertChannelSubscriptions);

            // //console.log(data.alertChannelSubscriptions[0].subscriptions.find(e => e.activated === true));
          } else {
            this.setState({
              isloadingAlertChannels: false,
              channelList: [],
              // pageCount : 0,
              // responseList_message : data.message,
              // responseList_status :"danger"
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isloadingAlertChannels: false,
              // responseList_message : err.message,
              // responseList_status :"danger"
            });
          }

          //console.log(err)
        });
    } else {
      this.setState({
        isloadingAlertChannels: false,
        hasActiveAccount: false,
      });
      // remote current account id from storage ( connectedUserData element )
      this.context.cleanUserAccount(accountId);
    }
  };

  /**
   *
   * @param {*} event
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      form_name,
      form_url,
      form_url_validate,
      form_threshold,
      form_threshold_error,
      form_maxResponseTime,
      form_maxResponseTime_error,
      form_expected_body
    } = this.state;
    // loading ... effect
    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });
    // we check the name ( name is required field )
    if (
      typeof form_name === 'undefined' ||
      form_name === null ||
      form_name === '' ||
      typeof form_url === 'undefined' ||
      form_url === null ||
      form_url === '' ||
      form_url_validate === false ||
      typeof form_threshold === 'undefined' ||
      form_threshold === null ||
      form_threshold === '' ||
      form_threshold_error !== '' ||
      typeof form_maxResponseTime === 'undefined' ||
      form_maxResponseTime === null ||
      form_maxResponseTime === '' ||
      form_maxResponseTime_error !== ''
    ) {
      this.setState({
        isLoading: false,
        form_response: 'Please complete all required fields',
        form_response_status: 'danger',
      });
      return;
    }

    // check the length of expected body
    if (form_expected_body.length > 200) {
      this.setState({
        isLoading: false,
        form_response: 'Expected body must be less than 200 characters',
        form_response_status: 'danger',
      });
      return;
    }

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      //console.log(this.state);

      // refont custom Headers List => to be easy as learning
      let customHeaders = {};
      this.state.httpHeader_elements.map((item) => {
        customHeaders[item.key] = item.value;
      });

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            is_active: this.state.isActiveMonitor,
            locations: this.state.selectedLocations,
            name: this.state.form_name,
            url: this.state.form_url,
            method: this.state.form_requestMethod,
            expected_status_code: this.state.form_expected_status_code,
            expected_body: this.state.form_expected_body,
            custom_headers: encodeURIComponent(JSON.stringify(customHeaders)),
            custom_body_format: this.state.httpBodyFormat,
            custom_body:
              this.state.httpBodyFormat !== 'none'
                ? encodeURIComponent(this.state.httpBodyContent)
                : '',
            followredirects: this.state.followRedirect,
            frequency: this.state.form_frequency,
            threshold: this.state.form_threshold,
            timeout: parseFloat(this.state.form_maxResponseTime) * 1000,
            alert_channel_subscriptions: this.state.channelList.map((item) => {
              return {
                account_id: item.account_id,
                activated: item.activated,
                id: item.id,
                type: item.type,
              };
            }),
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          // ? Google Analytics Event : Edit Monitor Data
          GA.GAevent('Monitors', 'Edit Monitor');

          // stop loading effect
          this.setState({
            isLoading: false,
            form_response: 'Your monitor has been successfully updated',
            form_response_status: 'success',
          });
          //console.log(res);
        })
        .catch((error) => {
          let stateItems = {};
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            const data = error.response.data;

            // default message
            stateItems.form_response = data.message;

            stateItems.form_response_status = 'danger';
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log('Error', error.message);
          }
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            // Save data in state
            this.setState(stateItems);
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  /**
   *
   *
   * @param {*} event
   * @memberof EditMonitorForm
   */
  deleteMonitor = async (event) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          // ? Google Analytics Event : Delete Monitor Item
          GA.GAevent('Monitors', 'Delete Monitor');

          // stop loading effect
          this.setState({
            isLoading: false,
            modalResponse: 'Monitor removed successfully',
            modalResponse_status: 'success',
            showModal_deleteMonitor: false,
          });
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your monitor',
              modalResponse_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  /**
   *
   */
  // selectTab = (event) => {
  // 	//console.log(event.currentTarget.dataset.target)
  // 	this.setState({
  // 		selectedSettingtab : event.currentTarget.dataset.target
  // 	})
  // }

  render__alertSettingsItemsDescription (item) {
    switch (item.type) {
      case 'EMAIL':
        return (
          <>
            <span className="text-muted">Email to </span>
            <span className="text-gray-dark">{item.config.address}</span>
          </>
        );
      case 'SLACK':
        return (
          <>
            <span className="text-muted">Slack hook to </span>
            {item.config.channel !== null ? (
              <span className="text-gray-dark">{item.config.channel}</span>
            ) : (
              ''
            )}
            <span className="text-muted small">
              <code>{item.config.webhook}</code>
            </span>
          </>
        );
      case 'SMS':
        return (
          <>
            <span className="text-muted">SMS to </span>
            <span className="text-gray-dark">{item.config.phoneNumber}</span>
          </>
        );
      case 'WEBHOOK':
        return (
          <>
            <span className="text-muted">Webhook </span>
            {item.config.name !== null ? (
              <span className="text-gray-dark">{item.config.name}</span>
            ) : (
              ''
            )}
            <span className="text-muted"> URL: </span>
            <span className="text-muted small">
              <code>{item.config.url}</code>
            </span>
          </>
        );
      case 'TELEGRAM':
        return (
          <>
            <span className="text-muted">Telegram to chat ID : </span>
            <span className="text-gray-dark">{item.config.chatid}</span>
          </>
        );
      case 'PAGERDUTY':
        return (
          <>
            <span className="text-muted">Pagerduty to service : </span>
            <span className="text-gray-dark">{item.config.service_name}</span>
            <span className="text-muted"> at </span>
            <span className="text-gray-dark">{item.config.account}</span>
            <span className="text-muted"> account</span>
          </>
        );
      case 'OPSGENIE':
        return (
          <>
            <span className="text-muted">Opsgenie API integration </span>
            <span className="text-gray-dark">{item.config.name}</span>
            <span className="text-muted"> - region : </span>
            <span className="text-gray-dark">
              {getOpsgenieRegionName(item.config.region)}
            </span>
          </>
        );
      case 'DISCORD':
        return (
          <>
            <span className="text-muted">Discord </span>
            {item.config.name !== null ? (
              <span className="text-gray-dark">{item.config.name}</span>
            ) : (
              ''
            )}
            <span className="text-muted"> URL: </span>
            <span className="text-muted small">
              <code>{`${item.config.url.substring(0, 54)}...`}</code>
            </span>
          </>
        );
    }
  }

  componentWillUnmount () {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render () {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Save Monitor Settings</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Saving...</span>
        </span>
      );
    }

    const {
      form_name,
      form_name_placeholder,
      form_url,
      form_url_placeholder,
      formIsSubmited,
      form_url_validate,
      loadingMonitorData,
      isloadingAlertChannels,
      form_threshold_error,
      form_maxResponseTime_error,
      form_expectedBodyCharacterCount_error
    } = this.state;

    let url_error_response = '';
    if (
      formIsSubmited === true &&
      form_url === '' &&
      form_url_validate === false
    )
      url_error_response = 'Url is required';
    else if (
      formIsSubmited === true &&
      form_url !== '' &&
      form_url_validate === false
    )
      url_error_response = 'Url is not valid';

    // Check if the user has an active account, else redirect the user to /intro page to create a new account
    if (this.state.hasActiveAccount === false) {
      // remote current account id from strage
      removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return <Redirect to="/intro" />;
    }

    if (this.state.modalResponse_status === 'success') {
      return <Redirect to="/" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Edit Monitor | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__monitor--form layout__blockInner">
              {this.state.monitorNotFound === true ? (
                <div className="layout__notFound white-card">
                  <h2>Not Found</h2>
                  <p>
                    Unfortunately, this monitor does not exist in the current
                    account.
                  </p>
                </div>
              ) : (
                <React.Fragment>
                  <div className="layout__header">
                    <div className="l_header__title limited-text">
                      <h2 className="limited-text" title={this.state.form_name}>
                        {this.state.form_name}
                      </h2>
                    </div>
                    <div className="l_header__btnaction">
                      <div className="btn-switch">
                        <Switch
                          onChange={
                            this.handleChange_formSwitchActivatedMonitor
                          }
                          checked={this.state.isActiveMonitor}
                          onColor="#1f80f9"
                          // onHandleColor="#2693e6"
                          handleDiameter={18}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          width={48}
                          height={22}
                          className="react-switch"
                          id={'switch-activated-monitor'}
                        />
                        <label>Activated</label>
                      </div>
                      <div
                        className="btn btn-danger"
                        onClick={this.openModal_deleteMonitor}
                      >
                        Delete Monitor
                      </div>
                      <div
                        className="btn btn-primary"
                        onClick={(event) => {
                          this.handleSubmit(event);
                        }}
                      >
                        {submitButtonContent}
                      </div>
                    </div>
                    <div className="l_header__description">
                      <div className="field-group submit-fields-group fluid">
                        <div
                          className={`alert alert-${this.state.form_response_status
                            } ${this.state.form_response === '' ? 'hide' : ''}`}
                        >
                          <div className="">{this.state.form_response}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="layout__body">
                    {loadingMonitorData ? (
                      <div className="loading-wrapper">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        <form
                          method="POST"
                          action=""
                          onSubmit={this.handleSubmit}
                        >
                          <div className="tab-settings__nav">
                            <ul>
                              <li
                                className={`no-link  ${this.state.tabs_form_selectedTab === 'request'
                                  ? 'active'
                                  : ''
                                  }`}
                                data-name="request"
                                onClick={this.handleChange__form_selectTab}
                              >
                                <span>Request</span>
                              </li>
                              <li
                                className={`no-link  ${this.state.tabs_form_selectedTab ===
                                  'locations'
                                  ? 'active'
                                  : ''
                                  }`}
                                data-name="locations"
                                onClick={this.handleChange__form_selectTab}
                              >
                                <span>Locations</span>
                              </li>
                              <li
                                className={`no-link  ${this.state.tabs_form_selectedTab === 'limits'
                                  ? 'active'
                                  : ''
                                  }`}
                                data-name="limits"
                                onClick={this.handleChange__form_selectTab}
                              >
                                <span>Limits</span>
                              </li>
                              <li
                                className={`no-link  ${this.state.tabs_form_selectedTab ===
                                  'scheduling'
                                  ? 'active'
                                  : ''
                                  }`}
                                data-name="scheduling"
                                onClick={this.handleChange__form_selectTab}
                              >
                                <span>Scheduling</span>
                              </li>
                              <li
                                className={`no-link  ${this.state.tabs_form_selectedTab ===
                                  'alert-settings'
                                  ? 'active'
                                  : ''
                                  }`}
                                data-name="alert-settings"
                                onClick={this.handleChange__form_selectTab}
                              >
                                <span>Alert Settings</span>
                              </li>
                            </ul>
                          </div>
                          <div className="tab-settings__content">
                            <div
                              className={`ts_content-item white-card margin-bottom-20 ${this.state.tabs_form_selectedTab === 'request'
                                ? 'active'
                                : ''
                                }`}
                            >
                              <h2 className="title">HTTP request settings</h2>
                              <p className="sub-title"></p>
                              <div className="field-group fluid">
                                <label htmlFor="" className="fluid">
                                  Name *
                                </label>
                                <input
                                  type="text"
                                  className={`textField fluid ${formIsSubmited === true && form_name === ''
                                    ? 'error'
                                    : ''
                                    }`}
                                  value={this.state.form_name}
                                  placeholder={form_name_placeholder}
                                  onChange={this.handleChange_formMonitorName}
                                />
                                <span
                                  className={`fieldText_alert--error ${formIsSubmited === true && form_name === ''
                                    ? ''
                                    : 'hide'
                                    }`}
                                >
                                  Name is required
                                </span>
                              </div>
                              <div className="field-group fluid">
                                <label htmlFor="" className="fluid">
                                  Endpoint *
                                </label>
                                <div className="field-group__multipleElement fluid ">
                                  <select
                                    className="textField "
                                    value={this.state.form_requestMethod}
                                    onChange={
                                      this.handleChange_formRequestMethod
                                    }
                                  >
                                    <option value="GET">GET</option>
                                    <option value="POST">POST</option>
                                    <option value="PUT">PUT</option>
                                    <option value="HEAD">HEAD</option>
                                    <option value="DELETE">DELETE</option>
                                    <option value="PATCH">PATCH</option>
                                    <option value="OPTIONS">OPTIONS</option>
                                  </select>
                                  <input
                                    type="text"
                                    className={`textField ${url_error_response !== '' ? 'error' : ''
                                      }`}
                                    value={this.state.form_url}
                                    placeholder={form_url_placeholder}
                                    onChange={this.handleChange_formURL}
                                  />
                                  <span
                                    className={`fieldText_alert--error ${url_error_response !== '' ? '' : 'hide'
                                      }`}
                                  >
                                    {url_error_response}
                                  </span>
                                </div>
                              </div>
                              <div className="field-group fluid">
                                <label htmlFor="" className="fluid">
                                  Follow Redirect
                                  <Tooltip
                                    placement="top"
                                    trigger={['hover']}
                                    overlay={
                                      <span>
                                        Follow all HTTP 3xx redirect responses.
                                      </span>
                                    }
                                  >
                                    <span className="question-badge"></span>
                                  </Tooltip>
                                </label>
                                <div className="radio-group fluid">
                                  <label htmlFor="form_followRedirect--enabled">
                                    <input
                                      type="radio"
                                      className="radio"
                                      id="form_followRedirect--enabled"
                                      name="form_followRedirect"
                                      value={true}
                                      defaultChecked={
                                        this.state.followRedirect === true
                                      }
                                      onChange={
                                        this.handleChange_formFollowRedirect
                                      }
                                    />
                                    Enabled
                                  </label>
                                  <label htmlFor="form_followRedirect--disabled">
                                    <input
                                      type="radio"
                                      className="radio"
                                      id="form_followRedirect--disabled"
                                      name="form_followRedirect"
                                      value={false}
                                      defaultChecked={
                                        this.state.followRedirect === false
                                      }
                                      onChange={
                                        this.handleChange_formFollowRedirect
                                      }
                                    />
                                    Disabled
                                  </label>
                                </div>
                              </div>
                              <div className="field-group fluid">
                                <label htmlFor="" className="fluid">
                                  Expected Status Code
                                  <Tooltip
                                    placement="top"
                                    trigger={['hover']}
                                    overlay={
                                      <span>
                                        Expected status code returned by request
                                      </span>
                                    }
                                  >
                                    <span className="question-badge"></span>
                                  </Tooltip>
                                </label>
                                <select
                                  className="textField"
                                  value={this.state.form_expected_status_code}
                                  onChange={
                                    this.handleChange_formExpectedStatusCode
                                  }
                                >
                                  {Object.entries(statusCodeToReasonPhrase).map(
                                    ([key, value]) => {
                                      return (
                                        <option key={key} value={key}>
                                          {key} - {value}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                              <div className="field-group fluid">
                                <label htmlFor="" className="fluid">
                                  Expected Body
                                  <Tooltip
                                    placement="top"
                                    trigger={['hover']}
                                    overlay={
                                      <span>
                                        Expected body returned by request
                                      </span>
                                    }
                                  >
                                    <span className="question-badge"></span>
                                  </Tooltip>
                                </label>
                                <input
                                  type="text"
                                  className="textField fluid"
                                  placeholder='{"status": "OK"}'
                                  value={this.state.form_expected_body}
                                  onChange={this.handleChange_formExpectedBody}
                                />
                              </div>
                              <span
                                className="fieldText_alert--error"
                              >
                                {form_expectedBodyCharacterCount_error}
                              </span>
                              <div className="field-group fluid">
                                <label htmlFor="" className="fluid">
                                  Custom http parameters
                                </label>
                                <div className="tab-group fluid ">
                                  <div className="tab_header">
                                    <ul>
                                      <li>
                                        <div
                                          className={`tab_header-item ${this.state.selectedHttpTab ===
                                            'httpHeader'
                                            ? 'selected'
                                            : ''
                                            }`}
                                          data-target="httpHeader"
                                          onClick={
                                            this.handleChange_selectedHttpTab
                                          }
                                        >
                                          Header
                                        </div>
                                      </li>
                                      <li>
                                        <div
                                          className={`tab_header-item ${this.state.selectedHttpTab ===
                                            'httpBody'
                                            ? 'selected'
                                            : ''
                                            }`}
                                          data-target="httpBody"
                                          onClick={
                                            this.handleChange_selectedHttpTab
                                          }
                                        >
                                          Body
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="tab_content">
                                    <ul>
                                      <li
                                        className={
                                          this.state.selectedHttpTab ===
                                            'httpHeader'
                                            ? 'selected'
                                            : ''
                                        }
                                        data-targeted-item="httpHeader"
                                      >
                                        <div className="multiple-items_lines">
                                          {this.state.httpHeader_elements.map(
                                            (item, i) => {
                                              return (
                                                <div
                                                  className="httpHeader--line"
                                                  key={i}
                                                >
                                                  <div className="field-group">
                                                    <input
                                                      type="text"
                                                      className="textField fluid "
                                                      name="key"
                                                      data-key={i}
                                                      value={item.key}
                                                      onChange={(e) =>
                                                        this.handleChange_formHttpHeader_lineContent(
                                                          e,
                                                          i
                                                        )
                                                      }
                                                      placeholder={
                                                        this.state
                                                          .httpHeader_field_key_placeholder
                                                      }
                                                    />
                                                  </div>
                                                  <div className="field-group">
                                                    <input
                                                      type="text"
                                                      className="textField fluid "
                                                      name="value"
                                                      data-key={i}
                                                      value={item.value}
                                                      onChange={(e) =>
                                                        this.handleChange_formHttpHeader_lineContent(
                                                          e,
                                                          i
                                                        )
                                                      }
                                                      placeholder={
                                                        this.state
                                                          .httpHeader_field_value_placeholder
                                                      }
                                                    />
                                                  </div>
                                                  <div
                                                    className="btn btn-option-icon httpHeader--line_remove"
                                                    onClick={(e) =>
                                                      this.handleChange_formHttpHeader_removeLine(
                                                        e,
                                                        i
                                                      )
                                                    }
                                                  ></div>
                                                </div>
                                              );
                                            }
                                          )}
                                          <div
                                            className="httpHeader--line_add"
                                            onClick={
                                              this
                                                .handleChange_formHttpHeader_addLine
                                            }
                                          >
                                            +Add Header
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        className={
                                          this.state.selectedHttpTab ===
                                            'httpBody'
                                            ? 'selected'
                                            : ''
                                        }
                                        data-targeted-item="httpBody"
                                      >
                                        <div className="multiple-items_textContentFormat">
                                          <div className="textContentFormat__options">
                                            <div className="radio-group">
                                              <label htmlFor="httpBody--none">
                                                <input
                                                  type="radio"
                                                  className="radio"
                                                  id="httpBody--none"
                                                  name="httpBodyFormat"
                                                  checked={
                                                    this.state
                                                      .httpBodyFormat ===
                                                    'none' ||
                                                    this.state
                                                      .httpBodyFormat === null
                                                  }
                                                  value="none"
                                                  onChange={
                                                    this
                                                      .handleChange_formHttpBody__chooseFormat
                                                  }
                                                />{' '}
                                                None
                                              </label>
                                              <label htmlFor="httpBody--form-params">
                                                <input
                                                  type="radio"
                                                  className="radio"
                                                  id="httpBody--form-params"
                                                  name="httpBodyFormat"
                                                  checked={
                                                    this.state
                                                      .httpBodyFormat ===
                                                    'form-params'
                                                  }
                                                  value="form-params"
                                                  onChange={
                                                    this
                                                      .handleChange_formHttpBody__chooseFormat
                                                  }
                                                />{' '}
                                                Form Params
                                              </label>
                                              <label htmlFor="httpBody--json">
                                                <input
                                                  type="radio"
                                                  className="radio"
                                                  id="httpBody--json"
                                                  name="httpBodyFormat"
                                                  checked={
                                                    this.state
                                                      .httpBodyFormat === 'json'
                                                  }
                                                  value="json"
                                                  onChange={
                                                    this
                                                      .handleChange_formHttpBody__chooseFormat
                                                  }
                                                />{' '}
                                                JSON
                                              </label>
                                              <label htmlFor="httpBody--raw-data">
                                                <input
                                                  type="radio"
                                                  className="radio"
                                                  id="httpBody--raw-data"
                                                  name="httpBodyFormat"
                                                  checked={
                                                    this.state
                                                      .httpBodyFormat ===
                                                    'raw-data'
                                                  }
                                                  value="raw-data"
                                                  onChange={
                                                    this
                                                      .handleChange_formHttpBody__chooseFormat
                                                  }
                                                />{' '}
                                                Raw Data
                                              </label>
                                            </div>
                                          </div>
                                          <div className="textContentFormat__appliedContent">
                                            <textarea
                                              className="textField"
                                              name=""
                                              id=""
                                              value={this.state.httpBodyContent}
                                              onChange={
                                                this
                                                  .hangleChange_formHttpBody_content
                                              }
                                              disabled={
                                                this.state.httpBodyFormat ===
                                                'none'
                                              }
                                              placeholder={
                                                this.state.httpBodyFormat ===
                                                  'none' ||
                                                  this.state.httpBodyFormat ===
                                                  'null'
                                                  ? ''
                                                  : this.state
                                                    .httpBodyFormat === 'json'
                                                    ? "{'key1': 'value1','key2': 'value2'}"
                                                    : this.state
                                                      .httpBodyFormat ===
                                                      'form-params'
                                                      ? 'key1=value1&key2=value2'
                                                      : this.state
                                                        .httpBodyFormat ===
                                                        'raw-data'
                                                        ? 'Raw Data'
                                                        : ''
                                              }
                                              rows="8"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`ts_content-item white-card margin-bottom-20 ${this.state.tabs_form_selectedTab === 'locations'
                                ? 'active'
                                : ''
                                }`}
                            >
                              <h2 className="title">
                                Data center locations{' '}
                                <span className="note-numbers">
                                  {this.state.selectedLocations.length} /{' '}
                                  {this.state.locationList.length}
                                </span>
                              </h2>
                              <p className="sub-title">
                                Make sure to pick at least two or more data
                                center locations where we can run your check.
                                Choosing multiple locations will give you a
                                better insight into the latency characteristics
                                of your application.
                              </p>
                              <div className="field-group fluid">
                                {/* <label htmlFor="" className="fluid">Test from *</label> */}
                                <div className="checkbox-group fluid monitor-regions">
                                  <ul>
                                    {this.state.locationList.map(
                                      (item, index) => {
                                        const locationInfo = getLocationData(
                                          item.name
                                        );
                                        if (typeof locationInfo !== 'undefined')
                                          return (
                                            <li key={index}>
                                              <label
                                                className={
                                                  this.state.selectedLocations.includes(
                                                    item.name
                                                  )
                                                    ? 'checked'
                                                    : ''
                                                }
                                                htmlFor={`location-${item.name}`}
                                              >
                                                <input
                                                  type="checkbox"
                                                  id={`location-${item.name}`}
                                                  value={item.name}
                                                  checked={this.state.selectedLocations.includes(
                                                    item.name
                                                  )}
                                                  onChange={
                                                    this
                                                      .handleChange_formSelectLocation
                                                  }
                                                />
                                                <ReactCountryFlag
                                                  countryCode={
                                                    locationInfo.countryCode
                                                  }
                                                  svg
                                                  title={
                                                    locationInfo.countryName
                                                  }
                                                  aria-label={
                                                    locationInfo.countryName
                                                  }
                                                />
                                                <div className="region-details">
                                                  <span className="region-name">
                                                    {item.location}
                                                  </span>
                                                  <span className="region-id">
                                                    {item.countryName}
                                                  </span>
                                                </div>
                                              </label>
                                            </li>
                                          );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`ts_content-item white-card margin-bottom-20 ${this.state.tabs_form_selectedTab === 'limits'
                                ? 'active'
                                : ''
                                }`}
                            >
                              <h2 className="title">Response time limits</h2>
                              <div className="field-group half-width">
                                <label htmlFor="" className="fluid">
                                  Threshold
                                </label>
                                <div
                                  className={`inputWrapper fluid ${formIsSubmited === true &&
                                    form_threshold_error !== ''
                                    ? 'error'
                                    : ''
                                    }`}
                                >
                                  <input
                                    type="number"
                                    className={`textField fluid `}
                                    value={this.state.form_threshold}
                                    onChange={
                                      this.handleChange_formMonitorThreshold
                                    }
                                  />
                                  <div className="suffix">ms</div>
                                </div>
                                <span
                                  className={`fieldText_alert--error ${formIsSubmited === true &&
                                    form_threshold_error !== ''
                                    ? ''
                                    : 'hide'
                                    }`}
                                >
                                  {form_threshold_error}
                                </span>
                              </div>
                              <div className="field-group half-width">
                                <label htmlFor="" className="fluid">
                                  Limit Timeout ( Response Time)
                                </label>
                                <div
                                  className={`inputWrapper fluid ${formIsSubmited === true &&
                                    form_maxResponseTime_error !== ''
                                    ? 'error'
                                    : ''
                                    }`}
                                >
                                  <input
                                    type="number"
                                    className={`textField fluid `}
                                    value={this.state.form_maxResponseTime}
                                    onChange={
                                      this
                                        .handleChange_formMonitorMaxResponseTime
                                    }
                                  />
                                  <div className="suffix">seconds</div>
                                </div>
                                <span
                                  className={`fieldText_alert--error ${formIsSubmited === true &&
                                    form_maxResponseTime_error !== ''
                                    ? ''
                                    : 'hide'
                                    }`}
                                >
                                  {form_maxResponseTime_error}
                                </span>
                                <div className="fieldText_note">
                                  Note that the default response Time limit is{' '}
                                  <strong>10 seconds</strong>{' '}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`ts_content-item white-card margin-bottom-20 ${this.state.tabs_form_selectedTab ===
                                'scheduling'
                                ? 'active'
                                : ''
                                }`}
                            >
                              <h2 className="title">Schedule</h2>
                              <p className="sub-title">
                                The check schedule determines how often we will
                                fire your checks. We advise running API checks
                                at least every 5 minutes and browser checks at
                                least every 15 minutes. There is no extra cost
                                for running your checks more often.
                              </p>
                              <div className="field-group">
                                <label htmlFor="" className="fluid">
                                  Ping frequency{' '}
                                  <span>
                                    The interval in which Odown pings your
                                    monitor
                                  </span>
                                </label>
                                <div className="field-group__multipleElement fluid ">
                                  <select
                                    className="textField "
                                    value={this.state.form_frequency}
                                    onChange={this.handleChange_formFrequency}
                                  >
                                    <option value="1">1 Min</option>
                                    <option value="5">5 Min</option>
                                    <option value="10">10 Min</option>
                                    <option value="15">15 Min</option>
                                    <option value="30">30 Min</option>
                                    <option value="60">1 Hour</option>
                                    <option value="360">6 Hours</option>
                                    <option value="720">12 Hours</option>
                                    <option value="1440">24 Hours</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`ts_content-item white-card margin-bottom-20 ${this.state.tabs_form_selectedTab ===
                                'alert-settings'
                                ? 'active'
                                : ''
                                }`}
                            >
                              <h2 className="title">Alert channels</h2>
                              <p className="sub-title">
                                Select on which alert channels you want to be
                                alerted when this check fails and recovers.
                              </p>
                              <table
                                className={`default-table ${this.state.channelList.length <= 0
                                  ? 'empty'
                                  : ''
                                  }`}
                              >
                                <thead
                                  className={
                                    this.state.channelList.length <= 0
                                      ? 'hide'
                                      : ''
                                  }
                                >
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th align="left">Details</th>
                                    <th align="center">Alert types</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isloadingAlertChannels ? (
                                    <tr>
                                      <td colSpan="5">
                                        <div className="loading-wrapper">
                                          <LoadingSpinner />
                                        </div>
                                      </td>
                                    </tr>
                                  ) : this.state.channelList.length <= 0 ? (
                                    <tr>
                                      <td colSpan="5">
                                        <div className="home-empty-state">
                                          <img
                                            alt="No Alert channels"
                                            src={icon_no_alert_channel}
                                            width="100px"
                                          />
                                          <h3>
                                            No Active Alert Channels found
                                          </h3>
                                          <p className="text-muted">
                                            Different alert channels to
                                            instantly alert your team and
                                            communicate incidents
                                          </p>
                                          <Link
                                            className=""
                                            to={{ pathname: `/settings/alert` }}
                                          >
                                            <div className="btn btn-primary">
                                              <svg
                                                version="1.1"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                className="octicon octicon-plus"
                                                aria-hidden="true"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"
                                                ></path>
                                              </svg>
                                              <span>New Alert Channels</span>
                                            </div>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ) : (
                                    this.state.channelList.map(
                                      (item, index) => {
                                        return (
                                          <tr data-id={item.id} key={index}>
                                            <td>
                                              <div className="btns_action">
                                                <Switch
                                                  onChange={
                                                    this
                                                      .handleChange_formAlertSettings
                                                  }
                                                  checked={
                                                    typeof item.activated !==
                                                      'undefined' &&
                                                      item.activated !== null
                                                      ? item.activated
                                                      : false
                                                  }
                                                  onColor="#1f80f9"
                                                  // onHandleColor="#2693e6"
                                                  handleDiameter={18}
                                                  uncheckedIcon={false}
                                                  checkedIcon={false}
                                                  // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                  // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                  width={48}
                                                  height={22}
                                                  className="react-switch"
                                                  id={item.id}
                                                />
                                              </div>
                                            </td>
                                            <td></td>
                                            <td align="left">
                                              <div className="field-content">
                                                {this.render__alertSettingsItemsDescription(
                                                  item
                                                )}
                                              </div>
                                            </td>
                                            <td align="center">
                                              <div className="field-content">
                                                <div className="alert-types">
                                                  <Tooltip
                                                    placement="top"
                                                    trigger={['hover']}
                                                    overlay={
                                                      <span>
                                                        Receive alerts when the
                                                        the check is recovered
                                                      </span>
                                                    }
                                                  >
                                                    <span
                                                      className={`alert-type alert-recovery ${item.send_recovery ===
                                                        true
                                                        ? 'active'
                                                        : ''
                                                        }`}
                                                    >
                                                      <svg
                                                        data-v-2614f063=""
                                                        viewBox="0 0 30 30"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          data-v-2614f063=""
                                                          d="m0 5.14285714 1.28571429-1.28571428 3.21428571 3.21428571 7.0714286-7.07142857 1.2857143 1.28571429-8.3571429 8.35714285z"
                                                          fill="#fff"
                                                          transform="translate(8.571429 10.285714)"
                                                        ></path>
                                                      </svg>
                                                    </span>
                                                  </Tooltip>
                                                  {/* <Tooltip placement="top" trigger={['hover']} 
																									overlay={<span>Receive alerts when the the check is degraded</span>}>
																									<span className={`alert-type alert-degraded ${item.send_degraded=== true ? 'active' : ''}`}><svg data-v-2614f063="" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path data-v-2614f063="" d="m14.8962832 18.244809c-.1719277 0-.3080352-.0573084-.4083264-.1719269-.1002912-.1146186-.1575996-.2650531-.1719269-.4513082l-.472799-4.8139535c-.0286546-.2005824-.0573088-.4620519-.0859635-.7844165-.0286546-.3223646-.0429817-.6268154-.0429817-.9133617v-2.38548589c0-.32952822.0823808-.58025246.2471449-.75218023.1647642-.17192777.4190702-.25789037.7629257-.25789037h.6017442c.3295282 0 .5802525.0859626.7521802.25789037.1719278.17192777.2578904.42265201.2578904.75218023v2.38548589c0 .2865463-.0179089.5909971-.0537272.9133617-.0358182.3223646-.0608907.5909977-.075218.8059074l-.472799 4.7924626c-.0143273.1862551-.0716357.3366896-.1719269.4513082-.1002912.1146185-.2435622.1719269-.4298173.1719269zm-1.1605066 3.2881021v-.6447259c0-.3152009.0859626-.5551798.2578903-.7199439.1719278-.1647641.4154885-.247145.7306894-.247145h.6017442c.3152009 0 .5551798.0823809.7199439.247145s.247145.404743.247145.7199439v.6447259c0 .3152009-.0823809.5551799-.247145.719944s-.404743.2471449-.7199439.2471449h-.6017442c-.3152009 0-.5587616-.0823808-.7306894-.2471449-.1719277-.1647641-.2578903-.4047431-.2578903-.719944z" fill="#fff"></path></svg></span> 
																								</Tooltip> */}
                                                  <Tooltip
                                                    placement="top"
                                                    trigger={['hover']}
                                                    overlay={
                                                      <span>
                                                        Receive alerts when the
                                                        the check fails
                                                      </span>
                                                    }
                                                  >
                                                    <span
                                                      className={`alert-type alert-failure ${item.send_failure ===
                                                        true
                                                        ? 'active'
                                                        : ''
                                                        }`}
                                                    >
                                                      <svg
                                                        data-v-2614f063=""
                                                        viewBox="0 0 30 30"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          data-v-2614f063=""
                                                          d="m5.78571429 4.83443522-4.75706811-4.75706811-.95127907.95127907 4.75706811 4.75706811-4.75706811 4.75706811.95127907.9512791 4.75706811-4.75706814 4.75706811 4.75706814.9512791-.9512791-4.75706814-4.75706811 4.75706814-4.75706811-.9512791-.95127907z"
                                                          fill="#fff"
                                                          transform="translate(9.428571 9.428571)"
                                                        ></path>
                                                      </svg>
                                                    </span>
                                                  </Tooltip>
                                                  <Tooltip
                                                    placement="top"
                                                    trigger={['hover']}
                                                    overlay={
                                                      <span>
                                                        Receive alerts when the
                                                        SSL certificate of the
                                                        domain used the check is
                                                        about to expire
                                                      </span>
                                                    }
                                                  >
                                                    <span
                                                      className={`alert-type alert-ssl ${item.ssl_expiry === true
                                                        ? 'active'
                                                        : ''
                                                        }`}
                                                    >
                                                      <svg
                                                        version="1.1"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        className="octicon octicon-lock"
                                                        aria-hidden="true"
                                                      >
                                                        <path
                                                          fillRule="evenodd"
                                                          d="M4 4v2h-.25A1.75 1.75 0 002 7.75v5.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-5.5A1.75 1.75 0 0012.25 6H12V4a4 4 0 10-8 0zm6.5 2V4a2.5 2.5 0 00-5 0v2h5zM12 7.5h.25a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25H12z"
                                                        ></path>
                                                      </svg>
                                                    </span>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        {this.state.showModal_deleteMonitor ? (
          <Modal
            title="Delete this monitor?"
            closeModal={this.closeModal_deleteMonitor}
            fixedWidthWindow="small-window"
          >
            <div>
              Deleting this check will erase all historical data like check
              results and alerts.
            </div>
            <div className="d-flex justify-content-flex-end margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_deleteMonitor}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger float-right margin-left-10"
                onClick={this.deleteMonitor}
              >
                Delete
              </button>
            </div>
            <div
              className={`modal-response alert alert-${this.state.modalResponse_status
                } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </Modal>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}
